<template>
    <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">

        <h4 class="font-weight-semibold d-lg-none">My Posts</h4>


        <div class="row">

            <!-- Sidebar nav -->
            <div class="col-lg-3">
                <aside class="sidebar mb-2 mb-sm-3 mb-sm-0 sticky-md-top">
                    <div class="sidebar-nav-select d-lg-none mb-sm-2">
                        <select @change="changeRoute($event)" class="form-select">
                            <option selected value="all">All Posts &#40;{{ stats.all }}&#41;</option>
                            <option value="published">Published &#40;{{ stats.published }}&#41;</option>
                            <option value="drafts">Drafts &#40;{{ stats.drafts }}&#41;</option>
                            <option value="active">Active &#40;{{ stats.active }}&#41;</option>
                            <option value="inactive">Inactive &#40;{{ stats.inactive }}&#41;</option>
                            <option value="flagged">Flagged &#40;{{ stats.flagged }}&#41;</option>
                            <option value="archived">Archived &#40;{{ stats.archived }}&#41;</option>
                        </select>
                    </div>
                    <ul class="sidebar-nav mb-3 mb-md-3 d-none d-lg-block">
                        <li class="sidebar-nav-item">
                            <router-link
                                to="/profile/surveys/all"
                                class="sidebar-nav-link"
                                :class="$route.path == '/profile/surveys' ? 'active' :''">
                                All Posts
                                <b-spinner v-if="wait" class="sidebar-link-spinner" small></b-spinner>
                                <span v-else class="post-count">{{ stats.all }}</span>
                            </router-link>
                        </li>
                        <li class="sidebar-nav-item">
                            <router-link
                                to="/profile/surveys/published"
                                class="sidebar-nav-link">
                                Published
                                <b-spinner v-if="wait" class="sidebar-link-spinner" small></b-spinner>
                                <span v-else class="post-count">{{ stats.published }}</span>
                            </router-link>
                        </li>
                        <li class="sidebar-nav-item">
                            <router-link
                                to="/profile/surveys/drafts"
                                class="sidebar-nav-link">
                                Drafts
                                <b-spinner v-if="wait" class="sidebar-link-spinner" small></b-spinner>
                                <span v-else class="post-count">{{ stats.drafts }}</span>
                            </router-link>
                        </li>
                        <li class="sidebar-nav-item">
                            <router-link
                                to="/profile/surveys/active"
                                class="sidebar-nav-link">
                                Active
                                <b-spinner v-if="wait" class="sidebar-link-spinner" small></b-spinner>
                                <span v-else class="post-count">{{ stats.active }}</span>
                            </router-link>
                        </li>
                        <li class="sidebar-nav-item">
                            <router-link
                                to="/profile/surveys/inactive"
                                class="sidebar-nav-link">
                                Inactive
                                <b-spinner v-if="wait" class="sidebar-link-spinner" small></b-spinner>
                                <span v-else class="post-count">{{ stats.inactive }}</span>
                            </router-link>
                        </li>
                        <li class="sidebar-nav-item">
                            <router-link
                                to="/profile/surveys/flagged" class="sidebar-nav-link">
                                Flagged
                                <b-spinner v-if="wait" class="sidebar-link-spinner" small></b-spinner>
                                <span v-else class="post-count">{{ stats.flagged }}</span>
                            </router-link>
                        </li>
                        <li class="sidebar-nav-item">
                            <router-link to="/profile/surveys/archived" class="sidebar-nav-link">
                                Archived
                                <b-spinner v-if="wait" class="sidebar-link-spinner" small></b-spinner>
                                <span v-else class="post-count">{{ stats.archived }}</span>
                            </router-link>
                        </li>
                    </ul>
                    <!-- Create post button -->
                    <b-button class="custom-btn custom-btn-success custom-btn-full" v-b-modal.create-post-modal>
                        <span class="d-none d-sm-inline-block">Create New Post</span>
                        <font-awesome-icon icon="plus" class="d-md-none d-flex w-auto m-auto"/>
                    </b-button>
                </aside>
            </div><!-- End column -->

            <div class="col-lg-9">
                <div class="row mb-3">
                    <div class="col-md-9 mb-2 mb-sm-0">
                        <b-form-input v-model="filter_search" class="form-control d-inline-block"
                                      placeholder="Search by title or tags..." type="search"/>
                    </div>
                    <div class="col-md-3">
                        <select class="form-select" v-model="filter_category">
                            <option value="">-- All Categories --</option>
                            <option :key="c.id" :value="c.id" v-for="c in categories">{{ c.name }}</option>
                        </select>
                    </div>
                </div>

                <Alerts :_success="success" :_error="error"></Alerts>

                <div class="survey-list-container">

                    <!-- Spinner to display while posts are loading -->
                    <div v-if="wait" class="list-spinner d-flex justify-content-center mb-3">
                        <b-spinner label="Loading..."></b-spinner>
                    </div>

                    <!-- Empty store shows if there's an error -->
                    <div v-if="!wait && error" class="posts-empty">
                        <div class="posts-empty-inner">
                            <div class="posts-empty-inner_img">
                                <img loading="lazy" src="@/assets/img/error-message.png" width="80" height="80"
                                     alt="Empty box"/>
                            </div>
                            <h4>{{ error }}</h4>
                            <h6>An error occurred while trying to fetch your posts.</h6>
                        </div>
                    </div>

                    <!-- Empty store shows if post count == 0 -->
                    <div v-if="!wait && !error && surveys.data.length == 0" class="posts-empty">
                        <div class="posts-empty-inner">
                            <div class="posts-empty-inner_img">
                                <img loading="lazy" src="@/assets/img/empty-box.png" width="80" height="80"
                                     alt="Empty box"/>
                            </div>
                            <h4>No posts to display.</h4>
                            <h6>There are no items to display for the selected view.</h6>
                        </div>
                    </div>

                    <!-- List of posts -->
                    <ul class="survey-list">
                        <li v-for="survey in surveys.data" :key="survey.id" class="survey-list-item">
                            <h6>
                                <router-link :to="`/profile/surveys/view/${survey.code}/details`">
                                    <font-awesome-icon :icon="survey.type == 'survey' ? 'poll-h' : 'comments'"
                                                       :title="`Post type: ${survey.type}`"/>
                                    {{ survey.title }}
                                </router-link>
                            </h6>
                            <p class="text-muted" v-if="survey.deleted_at">This survey has
                                <strong>{{ survey.questions_count }}</strong> questions and
                                <strong>{{ survey.respondents_count }}</strong> responses.</p>
                            <p v-if="!survey.deleted_at" class="excerpt" :title="survey.shortDescription">
                                {{ survey.shortDescription }}
                            </p>
                            <div v-if="survey.categories && !survey.deleted_at" class="categories mb-3">
                                <b-badge v-for="c in survey.categories" :key="c.id">
                                    {{ c.name }}
                                </b-badge>
                            </div>
                            <p v-if="survey.getTags && !survey.deleted_at" class="tags">
                                <span class="text-muted">Tags: </span>
                                <a href="#" v-for="t in survey.getTags" :key="t">{{ t }}&nbsp;</a>
                            </p>
                            <div class="survey-user">
                            <span class="datestamp">
                                {{ moment(survey.created_at).format("MMM D, YYYY HH:mm") }}
                            </span>
                            </div>

                            <!-- Survey Status -->
                            <div v-if="!survey.deleted_at" class="post-status">
                                <span v-if="!survey.published" class="status-badge draft">
                                    <font-awesome-icon icon="circle"/>
                                    Draft
                                </span>
                                <span v-else-if="!survey.active" class="status-badge inactive">
                                    <font-awesome-icon icon="circle"/>
                                    Inactive
                                </span>
                                <span v-else class="status-badge active">
                                    <font-awesome-icon icon="circle"/>
                                    Active
                                </span>
                            </div>
                            <div v-else class="post-status">
                                <span class="status-badge draft">
                                    <font-awesome-icon icon="circle"/>
                                    Archived
                                </span>
                            </div>

                            <b-alert show variant="warning" v-if="survey.flags_count !== 0"
                                     class="mb-3 mb-sm-0 mt-1 mt-sm-4">
                                <p class="mb-0 font-weight-semibold">
                                    <font-awesome-icon class="danger me-1" icon="exclamation-triangle"/>
                                    Your survey has been flagged {{ survey.flags_count }} time(s)
                                </p>
                                <small>Please note that if your survey is flagged more than 3 times, it may be pulled
                                    down
                                    for review.</small>

                                <div v-if="survey.flags">
                                    <hr/>
                                    <ul class="fa-ul">
                                        <li v-for="f in survey.flags" :key="f.id">
                                            <span class="fa-li"><font-awesome-icon class="danger"
                                                                                   icon="flag"/></span>{{ f.reason }}
                                            ({{ moment(f.created_at).format("MMM D, YYYY HH:mm") }})
                                        </li>
                                    </ul>
                                </div>

                            </b-alert>

                            <div class="survey-list-item-actions">
                                <ul v-if="!survey.deleted_at">
                                    <li>
                                        <router-link :to="`/profile/surveys/view/${survey.code}/questionnaire`"
                                                     class="text-decoration-none">
                                            <span class="large-number">{{ survey.questions_count }}</span>
                                            <span class="small-desc">questions</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link :to="`/profile/surveys/view/${survey.code}/respondents`"
                                                     class="text-decoration-none">
                                            <span class="large-number">{{ survey.respondents_count }}</span>
                                            <span class="small-desc">responses</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <b-dropdown v-if="!survey.deleted_at" right no-caret variant="link"
                                                    :id="`dropdown-${survey.code}`"
                                                    toggle-class="text-decoration-none"
                                                    class="survey-list-item-options">
                                            <template #button-content>
                                                <font-awesome-icon icon="chevron-down"/>
                                            </template>
                                            <b-dropdown-item>
                                                <router-link :to="`/profile/surveys/view/${survey.code}/questionnaire`">
                                                    <template v-if="!survey.published">Edit form</template>
                                                    <template v-else>View form</template>
                                                </router-link>
                                            </b-dropdown-item>

                                            <b-dropdown-item v-if="survey.published">
                                                <router-link :to="`/profile/surveys/view/${survey.code}/respondents`">
                                                    Respondents
                                                </router-link>
                                            </b-dropdown-item>



                                            <b-dropdown-item v-if="survey.published">
                                                <a
                                                    :class="!survey.published ? 'disabled' : ''"
                                                    href="#" @click.prevent="toggleSurveyActivation(survey)">
                                                    <template v-if="survey.active">
                                                        Deactivate
                                                    </template>
                                                    <template v-else>Activate</template>
                                                </a>
                                            </b-dropdown-item>

                                            <b-dropdown-item>
                                                <router-link :to="`/survey/respondents/${survey.code}`">
                                                    Responses II
                                                </router-link>
                                            </b-dropdown-item>

                                            <b-dropdown-item>
                                                <a @click.prevent="duplicateSurvey(survey)">
                                                    Duplicate Survey
                                                </a>
                                            </b-dropdown-item>

                                            <b-dropdown-item>
                                                <router-link :to="'/profile/surveys/create/'+survey.code">
                                                    Edit survey details
                                                </router-link>
                                            </b-dropdown-item>
                                            <b-dropdown-item>
                                                <a class="text-danger" @click.prevent="deleteSurvey(survey, 'no')">
                                                    Move to archive (trash)
                                                </a>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </li>
                                </ul>
                                <div v-else>
                                    <button @click.prevent
                                            class="custom-btn custom-btn-sm custom-btn-primary mt-3 me-2">
                                        Restore
                                    </button>
                                    <button @click.prevent="deleteSurvey(survey, 'yes')"
                                            class="custom-btn custom-btn-sm custom-btn-danger mt-3 me-2">
                                        Delete permanently
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>

                </div><!-- End survey list container -->

                <!-- Pagination -->
                <Pagination
                    :limit="1"
                    :showDisabled="true"
                    :data="surveys"
                    @pagination-change-page="loadSurveys">
                </Pagination>

            </div><!-- End column -->

        </div><!-- End row -->

    </div><!-- End container-lg -->
</template>

<script>
import Alerts from "../Helpers/Alerts"
import Pagination from 'laravel-vue-pagination'

export default {
    name: "Surveys",
    components: {Alerts, Pagination},
    props: ['tab'],
    data() {
        return {
            surveys: {data: []},
            categories: [],
            stats: {
                all: 0,
                published: 0,
                drafts: 0,
                active: 0,
                inactive: 0,
                archived: 0,
            },

            // Filters
            filter_search: '',
            filter_category: '',
        }
    },
    mounted() {
        this.loadSurveys()
        this.loadSurveyCounts()
        this.getCategories()
    },
    watch: {
        $route() {
            this.loadSurveys()
        },
        filter_category() {
            this.loadSurveys()
        },
        filter_search(a, b) {
            if (b.length >= 2) {
                this.loadSurveys()
            }
        }
    },
    methods: {
        changeRoute(event) {
            this.$router.push({path: '/profile/surveys/' + event.target.value})
        },
        loadSurveyCounts() {
            this.$http.get('/api/profile/surveys/stats').then(response => {
                this.stats = response.data.data
            })
        },
        loadSurveys(page = 1) {
            window.scroll(0, 0)
            this.setLoading()
            this.clear()
            let search = this.filter_search.replace('#', '')
            this.$http.get('/api/profile/surveys/' + this.tab + '?page=' + page + '&category=' + this.filter_category + '&search=' + search).then(res => {
                this.surveys = res.data.data
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            })
        },
        getCategories() {
            this.$http.get('/api/categories').then(res => {
                this.categories = res.data.data
            })
        },
        toggleSurveyActivation(survey) {
            if (confirm('Are you sure you want to toggle survey activation')) {
                this.$http.post(`/api/profile/survey/${survey.code}/activate`).then(response => {
                    this.survey = response.data.data
                    this.loadSurveys()
                    this.loadSurveyCounts()
                })
            }
        },
        duplicateSurvey(survey) {
            this.success = "Duplicating survey, please wait..."
            window.scroll(0,0)
            if (confirm('Are you sure you want to duplicate this survey. Responses will not be copies')) {
                this.$http.post(`/api/profile/survey/${survey.code}/duplicate`).then(res => {
                    this.$router.push(`/profile/surveys/view/${res.data.data.code}/details`)
                    this.success = "Survey duplicated successfully!"
                }).catch(error => {
                    this.showError(error)
                })
            }
        },
        deleteSurvey(survey, permanent) {
            if (confirm('Are you sure you want to delete this survey ' + (permanent === 'yes' ? 'permanently?' : '?'))) {
                this.$http.post(`/api/profile/survey/${survey.code}/delete?permanent=` + permanent).then(() => {
                    this.loadSurveys()
                    this.loadSurveyCounts()
                }).catch(error => {
                    this.showError(error)
                })
            }
        },
        deletePermSurvey(survey) {
            if (confirm('Are you sure you want to delete this Survey Permanently')) {
                this.$http.post(`/api/profile/survey/${survey.code}/delete`).then(() => {
                        console.log('delete')
                        //  this.loadSurveys()
                        //  this.loadSurveyCounts()
                        //  this.surveys.data.$remove(this.survey);
                    }
                ).catch(error => {
                    this.showError(error)
                })
            }
        }
    }
}
</script>

<style scoped>
</style>
